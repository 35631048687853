import Vue from "vue";
import Vuex from "vuex";
import Answer, {
  GetMysqlDataStructure,
  GetAnswerObject
} from "../library/Answer";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentQuestion: 1,
    currentSection: 1,
    nextQuestion: 1,
    nextSection: 1,
    // answers: '',
    mysqlJsonStructure: "",
    answersObj: [],
    answersPath: [],
    answer: {},
    survey: {}
  },
  mutations: {
    SECTION(state, payload) {
      state.nextSection = payload;
    },
    QUESTION(state, payload) {
      state.currentQuestion = payload;
    },
    NEXTSECTION(state, payload) {
      state.nextSection = payload;
    },
    NEXTQUESTION(state, payload) {
      state.nextQuestion = payload;
    },
    ADDANSWERPATH(state, payload) {
      state.answersPath.push(payload);
    },
    CREATESURVEY(state, payload) {
      state.survey = payload;
    },
    ADDANSWERS(state, payload) {
      payload.nextQuestionId = state.nextQuestion;
      payload.nextSectionId = state.nextSection;
      payload.answersObj = state.answersObj;
      payload.mysqlJsonStructure = state.mysqlJsonStructure;
      const answer = new Answer(payload);
      state.mysqlJsonStructure = GetMysqlDataStructure(answer);
      state.answersObj = GetAnswerObject(answer);
    }
  },
  actions: {
    currentQuestion({ commit }, payload) {
      commit("QUESTION", payload);
    },
    currentSection({ commit }, payload) {
      commit("SECTION", payload);
    },
    nextQuestion({ commit }, payload) {
      commit("NEXTQUESTION", payload);
    },
    nextSection({ commit }, payload) {
      commit("NEXTSECTION", payload);
    },
    addAnswer({ commit }, payload) {
      commit("ADDANSWERS", payload);
      commit("ADDANSWERPATH", payload);
    },
    addAnswerPath({ commit }, payload) {
      commit("ADDANSWERPATH", payload);
    },
    createSurvey({ commit }, payload) {
      commit("CREATESURVEY", payload);
    }
  },
  modules: {}
});
