<template>
  <button
    v-if="showBackButton"
    @click="onNavigateBack"
    class="btn btn-default btn-lg answers-button back-btn"
  >
    Back
  </button>
</template>

<script>
export default {
  methods: {
    onNavigateBack() {
      this.$emit("navigateBack");
    }
  },
  computed: {
    showBackButton() {
      return this.$store.state.answersPath.length > 0 ? true : false;
    }
  }
};
</script>
