import "@babel/polyfill";
import "mutationobserver-shim";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../public/style.scss";
import "fullpage.js/vendors/scrolloverflow";
import VueFullPage from "vue-fullpage.js/dist/vue-fullpage.min";
import Amplify from "aws-amplify";
// import awsconfig from "./aws-exports";

Amplify.configure({
  aws_project_region: "eu-west-2",
  aws_appsync_graphqlEndpoint:
    "https://3rdelifxpbee5kxahs5i2i7sxe.appsync-api.eu-west-2.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-sprxh5sfpndazknbjughu27waa"
});
Vue.use(VueFullPage);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
