import { API } from "aws-amplify";

export function getSurveyById(surveyId) {
  const getSurvey = /* GraphQL */ `
    query GetSurvey($id: String!) {
      getSurvey(id: $id) {
        id
        name
        question_blob
      }
    }
  `;

  var surveyOutput = new Promise(async function (resolve, reject) {
    const {
      data: { getSurvey: survey },
    } = await API.graphql({
      query: getSurvey,
      variables: { id: surveyId }, // this.$route.params.applicationid.toLowerCase() // E5D1F6CC4DE94F43A0B09B727B9A5CCC     e5d1f6cc-4de9-4f43-a0b0-9b727b9a5ccc
      authMode: "API_KEY",
    });
    return resolve(survey);
  });
  return surveyOutput;
}