<template>
  <div>
    <div v-bind:class="message.type" v-if="message">
      {{ message.message }}
    </div>

    <BackNavigationButton @navigateBack="onNavigateBack" />

    <full-page ref="fullpage" :options="options" :skip-init="true" >
      <div
        class="section"
        v-for="section in $store.state.survey.sections"
        :key="section.id"
      >
        <div
          class="slide"
          v-for="question in section.items"
          :key="question.id"
          :data-anchor="`slide${question.id}`"
        >
          <div class="container">
            <BodyImageSelector
              v-if="
                question.type === 'question' && question.subType === 'bodyimage'
              "
              :options="question"
              :questionId="question.id"
              :section="section"
              :question="question.question"
              @saveAnswer="onSetAnswer"
            />
            <FinalPageQuestionText
              v-if="
                question.type === 'page' &&
                  question.subType === 'text' &&
                  question.nextQuestion === -1
              "
              :question="question"
              :questionId="question.id"
              :sectionId="section.id"
              :section="section"
              @saveAnswer="onSetAnswer"
            />
            <QuestionText
              v-if="
                question.type === 'page' &&
                  question.subType === 'text' &&
                  question.nextQuestion !== -1
              "
              :question="question"
              :questionId="question.id"
              :sectionId="section.id"
              :section="section"
              @saveAnswer="onSetAnswer"
            />
            <QuestionRadio
              v-if="
                question.type === 'question' && question.subType === 'buttons'
              "
              :options="question.choices"
              :question="question"
              :questionId="question.id"
              :sectionId="section.id"
              :section="section"
              @saveAnswer="onSetAnswer"
            />
          </div>
        </div>
      </div>
    </full-page>
  </div>
</template>

<script>
import QuestionText from "@/components/QuestionText.vue";
import QuestionRadio from "@/components/QuestionRadio.vue";
import BodyImageSelector from "@/components/BodyImageSelector.vue";
import FinalPageQuestionText from "@/components/FinalPageQuestionText.vue";
import BackNavigationButton from "@/components/BackNavigationButton.vue";
import * as Assessment from "./../library/Assessment";
import * as Survey from "./../library/Survey";

import moment from "moment";
export default {
  name: "app",
  components: {
    QuestionText,
    QuestionRadio,
    BodyImageSelector,
    FinalPageQuestionText,
    BackNavigationButton
  },
  data() {
    return {
      options: {
        licenseKey: "0FA61F03-E0BA4D86-B4A610D4-1C4E9E94",
        afterLoad: this.afterLoad,
        afterRender: this.afterRender,
        scrollOverflow: true,
        sectionsColor: [
          "#C6D436",
          "#ff5f45",
          "#0798ec",
          "#fec401",
          "#fec401",
          "#1bcee6",
          "#ee1a59",
          "#2c3e4f",
          "#ba5be9",
          "#b4b8ab"
        ],
        scrollingSpeed: 400
      },
      // questionnaire: { sections: null },
      referrer: null,
      message: false
    };
  },
  async mounted() {
    await this.parseQuestionsData();
    this.$refs.fullpage.init();
    this.$refs.fullpage.api.setAllowScrolling(false);
    // this.$refs.fullpage.api.silentMoveTo(1, 5);
  },
  methods: {
    afterLoad() {
      if (this.$refs.fullpage.api) {
        this.$refs.fullpage.api.setAllowScrolling(false);
      }
    },
    afterRender() {
      if (this.$refs.fullpage.api) {
        this.$refs.fullpage.api.setAllowScrolling(false);
      }
    },
    async onSetAnswer() {
      let answers = this.$store.state.mysqlJsonStructure.replace(/,\s*$/, "");
      const updateAss = await Assessment.addAssessmentAnswer(
        this.$route.params.assessmentId.toLowerCase(),
        this.$route.params.appKey.toLowerCase(),
        `${answers}`
      );

      if (updateAss && this.$store.state.nextSection !== -1) {
        // this.$refs.fullpage.api.moveTo(
        //   parseInt(this.$store.state.nextSection),
        //   `slide${this.$store.state.nextQuestion}`
        // );
        this.$refs.fullpage.api.silentMoveTo(
          parseInt(this.$store.state.nextSection),
          `slide${this.$store.state.nextQuestion}`
        );
      } else {
        const updateAssCompleteDate = await Assessment.updateAssessmentCompletedDated(
          this.$route.params.assessmentId.toLowerCase(),
          this.$route.params.appKey.toLowerCase(),
          moment().format("YYYY-MM-DD HH:mm:ss")
        );
        if (updateAssCompleteDate) {
          window.location.replace(this.referrer); // Redirect
        }
      }
    },
    async parseQuestionsData() {
      // console.log(moment().format("YYYY-MM-DD HH:mm:ss"), "completed_at"); // "2021-03-14 19:07:45"
      const assessment = await Assessment.getAssessmentById(
        this.$route.params.appKey.toLowerCase(),
        this.$route.params.assessmentId.toLowerCase()
      );

      if (assessment) {
        this.referrer = assessment.referrer;
        const survey = await Survey.getSurveyById(
          assessment.survey_id.toLowerCase()
        );

        if (survey) {
          let json = JSON.parse(survey.question_blob);
          json = JSON.parse(json);
          this.$store.state.createSurvey = json;
          this.$store.dispatch("createSurvey", json);
          // this.questionnaire = json;
        }
      } else {
        // Display message
        this.message = {
          message:
            "Problem getting DSE Assessment.  Please contact HealthyPerformance.",
          type: "alert alert-danger"
        };
      }
    },
    onNavigateBack() {
      const answerPath = this.$store.state.answersPath.pop();
      this.$refs.fullpage.api.silentMoveTo(
        parseInt(answerPath.sectionId),
        `slide${answerPath.questionId}`
      );
    }
  }
};
</script>
