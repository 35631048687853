<template>
  <div class="pyro">
    <div class="before"></div>
    <img
      v-if="question.imageUrl"
      :src="question.imageUrl"
      :tag="question.imageText"
    />
    <img
      v-else-if="section.imageUrl"
      :src="section.imageUrl"
      :tag="section.imageText"
    />
    <h3>{{ question.headline }}</h3>
    <p class="my-4">
      {{ question.body }}
    </p>
    <button
      v-if="question.buttonText"
      class="btn btn-default btn-lg answers-button my-2"
      @click="onSubmitBtn()"
    >
      {{ question.buttonText }}
    </button>
    <button
      v-else
      class="btn btn-default btn-lg answers-button my-2"
      @click="onSubmitBtn()"
    >
      Next
    </button>
    <div class="after"></div>
  </div>
</template>

<script>
export default {
  props: {
    question: Object,
    questionId: Number,
    sectionId: Number,
    section: Object
  },
  data() {
    return {};
  },
  methods: {
    onSubmitBtn() {
      this.$store.dispatch("nextQuestion", this.question.nextQuestion);
      this.$store.dispatch("nextSection", this.question.nextSection);
      this.$emit("saveAnswer");
    }
  }
};
</script>

<style lang="scss">
$particles: 80;
$width: 600;
$height: 1000;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
    random($width)-$width /
      2 +
      px
      random($height)-$height /
      1.2 +
      px
      hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff600;
}
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation-delay($settings) {
  -moz-animation-delay: $settings;
  -webkit-animation-delay: $settings;
  -o-animation-delay: $settings;
  -ms-animation-delay: $settings;
  animation-delay: $settings;
}

@mixin animation-duration($settings) {
  -moz-animation-duration: $settings;
  -webkit-animation-duration: $settings;
  -o-animation-duration: $settings;
  -ms-animation-duration: $settings;
  animation-duration: $settings;
}

@mixin animation($settings) {
  -moz-animation: $settings;
  -webkit-animation: $settings;
  -o-animation: $settings;
  -ms-animation: $settings;
  animation: $settings;
}

@mixin transform($settings) {
  transform: $settings;
  -moz-transform: $settings;
  -webkit-transform: $settings;
  -o-transform: $settings;
  -ms-transform: $settings;
}

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation(
    (
      1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards
    )
  );
}

.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}

@include keyframes(bang) {
  to {
    box-shadow: $box-shadow;
  }
}

@include keyframes(gravity) {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%,
  19.9% {
    margin-top: 50%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 60%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 30%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 50%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 80%;
    margin-left: 80%;
  }
}
</style>
