<template>
  <div>
    <div class="row">
      <div class="col">
        <div style="width: 100%; height: 100%">
          <canvas
            ref="widget"
            style="display: block; width: 100%; height: 500px; cursor: pointer"
          ></canvas>
        </div>
      </div>
      <div class="col-8">
        <img
          v-if="options.imageUrl"
          :src="options.imageUrl"
          :tag="options.imageText"
          class="my-2"
          style="max-width: 70%"
        />
        <img
          v-else-if="section.imageUrl"
          :src="section.imageUrl"
          :tag="section.imageText"
          class="my-2"
          style="max-width: 70%"
        />
        <h3 class="mb-2 mt-2">{{ question }}</h3>
        <div>
          <button
            style="height: auto"
            class="btn btn-default btn-lg answers-button my-2"
            @click="onSubmitBtn()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BodyImageSelector",
  props: {
    value: {
      type: Object,
      default: function() {
        return {};
      }
    },
    options: Object,
    questionId: Number,
    section: Object,
    question: String
  },
  data() {
    return {
      renderRequest: null,
      config: {
        bodyWidth: 114.07797,
        bodyHeight: 298.53137,
        lineColour: "white",
        unselectedColour: "#A7A9AC",
        unselectedHoverColour: "#7D7F81",
        selectedColour: "#ED1C24",
        selectedHoverColour: "#AA090F"
      },
      canvasWidth: 1,
      canvasHeight: 1,
      context: null,
      mouse: {
        x: 0,
        y: 0
      },
      bodyPartsSelected: {
        head: this.value.head || false,
        neck: this.value.neck || false,
        shoulder_right: this.value.shoulder_right || false,
        shoulder_left: this.value.shoulder_left || false,
        upperarm_right: this.value.upperarm_right || false,
        upperarm_left: this.value.upperarm_left || false,
        lowerarm_right: this.value.lowerarm_right || false,
        lowerarm_left: this.value.lowerarm_left || false,
        hand_right: this.value.hand_right || false,
        hand_left: this.value.hand_left || false,
        back: this.value.back || false,
        hips: this.value.hips || false,
        thigh_right: this.value.thigh_right || false,
        thigh_left: this.value.thigh_left || false,
        knee_right: this.value.knee_right || false,
        knee_left: this.value.knee_left || false,
        calf_right: this.value.calf_right || false,
        calf_left: this.value.calf_left || false,
        foot_right: this.value.foot_right || false,
        foot_left: this.value.foot_left || false
      },
      bodyPartNames: [
        "head",
        "neck",
        "shoulder_right",
        "shoulder_left",
        "upperarm_right",
        "upperarm_left",
        "lowerarm_right",
        "lowerarm_left",
        "hand_right",
        "hand_left",
        "back",
        "hips",
        "thigh_right",
        "thigh_left",
        "knee_right",
        "knee_left",
        "calf_right",
        "calf_left",
        "foot_right",
        "foot_left"
      ],
      bodyParts: {
        head: {
          x: 65.3702,
          y: 35.8382,
          points: [
            [0.0, 0.0, -1.531, -2.943, -6.248, -2.943],
            [-10.966, -2.943, -12.629, -0.011, -12.629, -0.011],
            [-14.014, 1.826, -14.222, 5.422, -14.222, 5.422],
            [-14.532, 5.01, -14.943, 5.062, -15.458, 6.143],
            [-15.974, 7.225, -16.797, 8.462, -17.261, 10.573],
            [-17.726, 12.685, -16.18, 12.737, -16.18, 12.737],
            [-18.362, 22.895, -11.391, 26.846, -5.655, 26.608],
            [7.095, 25.519, 3.492, 12.769, 3.492, 12.769],
            [3.492, 12.769, 4.534, 12.769, 4.629, 11.631],
            [4.725, 10.494, 4.393, 9.453, 2.994, 6.431],
            [2.299, 4.926, 1.607, 5.436, 1.607, 5.436],
            [1.363, 3.086, 0.752, 1.136, 0.0, 0.0]
          ],
          path: null
        },
        neck: {
          x: 65.3663,
          y: 42.5374,
          points: [
            [-0.124, 0.294, -0.128, 0.641, -0.128, 1.145],
            [-0.128, 2.587, 0.081, 5.024, 0.081, 5.024],
            [0.003, 5.024],
            [0.003, 5.024, -1.528, 2.082, -6.245, 2.082],
            [-10.963, 2.082, -12.626, 5.013, -12.626, 5.013],
            [-12.617, 4.932, -12.364, 2.562, -12.364, 1.145],
            [-12.364, 0.779, -12.366, 0.496, -12.415, 0.256],
            [-12.415, 0.256, -11.191, -4.222, -6.312, -4.222],
            [-1.433, -4.222, 0.0, 0.0, 0.0, 0.0]
          ],
          path: null
        },
        shoulder_right: {
          x: 95.339,
          y: 70.8348,
          points: [
            [-0.085, 1.248, -0.321, 2.83, -0.832, 4.94],
            [-2.87, 12.477, -6.188, 13.377, -9.281, 14.726],
            [-10.51, 15.263, -11.985, 15.913, -13.492, 16.583],
            [-13.492, 16.583, -19.216, 7.16, -13.995, 1.939],
            [-8.774, -3.282, 0.0, 0.0, 0.0, 0.0]
          ],
          path: null
        },
        shoulder_left: {
          x: 18.7412,
          y: 70.8115,
          points: [
            [0.086, 1.245, 0.322, 2.822, 0.831, 4.922],
            [2.869, 12.459, 6.187, 13.359, 9.28, 14.708],
            [10.509, 15.246, 11.984, 15.895, 13.49, 16.565],
            [13.49, 16.565, 19.365, 7.143, 14.144, 1.921],
            [8.923, -3.299, 0.001, 0.0, 0.001, 0.0]
          ],
          path: null
        },
        upperarm_right: {
          x: 85.6488,
          y: 115.696,
          points: [
            [-0.529, 3.147, -0.861, 5.444, -0.861, 5.444],
            [-1.033, 6.75],
            [-2.831, 17.306],
            [-0.349, 26.634, 6.956, 28.681, 6.956, 28.681],
            [6.861, 26.642, 7.833, 22.495, 7.335, 16.594],
            [7.335, 13.04, 7.278, 11.598, 8.97, 6.641],
            [9.533, 4.993, 9.947, 3.654, 10.271, 2.229],
            //[10.283000, 2.232000],
            [10.283, 2.232, 6.576, -0.977, 0.0, 0.0]
          ],
          path: null
        },
        upperarm_left: {
          x: 28.619,
          y: 115.451,
          points: [
            [0.221, 1.525, 0.443, 3.154, 0.674, 4.906],
            [0.681, 4.867, 0.685, 4.846, 0.685, 4.846],
            [0.685, 4.846, 0.716, 5.076, 0.743, 5.429],
            [0.775, 5.679, 0.808, 5.919, 0.841, 6.174],
            [1.694, 12.856, 2.688, 17.122, 2.688, 17.122],
            [0.206, 26.45, -7.098, 28.497, -7.098, 28.497],
            [-7.003, 26.458, -7.976, 22.311, -7.477, 16.41],
            [-7.477, 12.856, -7.419, 11.414, -9.112, 6.457],
            [-9.675, 4.809, -10.089, 3.47, -10.413, 2.045],
            //[-10.424000, 2.048000],
            [-10.424, 2.048, -6.577, -0.977, 0.0, 0.0]
          ],
          path: null
        },
        lowerarm_right: {
          x: 103.317,
          y: 147.362,
          points: [
            [-0.857, 2.092, -1.23, 6.577, -1.697, 14.182],
            [-2.071, 20.242, -2.362, 23.266, -2.979, 25.979],
            //[-2.968000, 25.981000],
            [-2.968, 25.981, -6.675, 22.772, -13.251, 23.75],
            [-12.393, 18.636, -11.017, 11.279, -9.707, 7.878],
            [-8.493, 4.723, -7.042, 0.292, -7.042, 0.292],
            [-7.042, 0.292, -6.829, -0.369, -6.746, -0.953],
            [-3.194, -2.452, 0.0, 0.0, 0.0, 0.0]
          ],
          path: null
        },
        lowerarm_left: {
          x: 10.7608,
          y: 147.362,
          points: [
            [0.857, 2.091, 1.231, 6.575, 1.699, 14.182],
            [2.071, 20.242, 2.363, 23.266, 2.98, 25.979],
            //[2.970000, 25.981000],
            [2.97, 25.981, 6.816, 22.957, 13.394, 23.934],
            [12.398, 17.083, 11.417, 12.317, 9.708, 7.878],
            [8.447, 4.603, 6.635, 0.33, 6.634, -1.09],
            [3.082, -2.588, 0.0, 0.0, 0.0, 0.0]
          ],
          path: null
        },
        hand_right: {
          x: 94.3234,
          y: 148.632,
          points: [
            [0.038, -0.269, 0.05, -0.521, 0.0, -0.685],
            [-0.535, -3.292, -0.311, -5.979, 0.031, -8.43],
            //[0.111000, -9.018000],
            [0.111, -9.018, 0.15, -9.292, 0.166, -9.416],
            [0.924, -15.531, 1.587, -18.375, 2.44, -18.186],
            [3.293, -17.996, 2.771, -15.293, 2.631, -13.586],
            [2.487, -11.881, 2.156, -9.796, 2.44, -9.701],
            [2.725, -9.605, 2.82, -10.838, 3.247, -13.68],
            [3.672, -16.527, 4.764, -19.228, 5.332, -19.086],
            [5.9, -18.943, 6.137, -18.422, 5.806, -16.621],
            [5.474, -14.817, 4.147, -9.18, 4.478, -9.133],
            [4.812, -9.084, 5.947, -13.159, 6.469, -16.052],
            [6.991, -18.943, 7.559, -20.081, 8.318, -19.844],
            [9.076, -19.606, 8.982, -18.895, 8.46, -16.289],
            [7.939, -13.68, 6.612, -8.563, 7.134, -8.422],
            [7.654, -8.279, 8.84, -14.866, 9.266, -16.527],
            [9.692, -18.186, 10.877, -19.464, 11.067, -17.759],
            [11.257, -16.052, 9.787, -9.084, 9.55, -7.568],
            [9.313, -6.052, 10.451, -6.668, 11.351, -7.995],
            [12.251, -9.321, 14.053, -9.938, 14.479, -9.273],
            [14.997, -8.467, 12.915, -7.142, 11.683, -4.631],
            [10.451, -2.116, 8.697, -1.406, 7.37, -0.08],
            [7.356, -0.066, 6.972, 0.486, 6.958, 0.5],
            [6.884, 0.636, 6.812, 0.787, 6.745, 0.953],
            [6.745, 0.953, 3.551, -1.499, 0.0, 0.0]
          ],
          path: null
        },
        hand_left: {
          x: 19.6059,
          y: 148.815,
          points: [
            [0.0, 0.0, 0.058, -0.371, 0.146, -0.657],
            [0.842, -2.667, 0.427, -5.813, 0.092, -8.212],
            [0.122, -8.192, 0.143, -8.18, 0.143, -8.18],
            [0.143, -8.18, 0.165, -8.266, 0.146, -8.378],
            [0.134, -8.433, 0.129, -8.503, 0.103, -8.569],
            [0.083, -8.622, 0.052, -8.672, 0.021, -8.724],
            [-0.004, -8.911, -0.032, -9.106, -0.055, -9.279],
            [-0.812, -15.394, -1.476, -18.238, -2.329, -18.048],
            [-3.182, -17.859, -2.66, -15.156, -2.518, -13.449],
            [-2.376, -11.744, -2.045, -9.659, -2.329, -9.564],
            [-2.613, -9.468, -2.708, -10.701, -3.134, -13.542],
            [-3.561, -16.39, -4.65, -19.09, -5.219, -18.949],
            [-5.788, -18.806, -6.024, -18.285, -5.693, -16.484],
            [-5.361, -14.68, -4.034, -9.042, -4.366, -8.996],
            [-4.698, -8.947, -5.835, -13.022, -6.356, -15.915],
            [-6.878, -18.806, -7.447, -19.944, -8.207, -19.707],
            [-8.964, -19.469, -8.869, -18.758, -8.348, -16.152],
            [-7.826, -13.542, -6.5, -8.426, -7.021, -8.285],
            [-7.541, -8.142, -8.728, -14.729, -9.153, -16.39],
            [-9.58, -18.048, -10.765, -19.327, -10.954, -17.622],
            [-11.146, -15.915, -9.676, -8.947, -9.438, -7.431],
            [-9.201, -5.915, -10.34, -6.531, -11.24, -7.858],
            [-12.141, -9.184, -13.94, -9.8, -14.367, -9.136],
            [-14.887, -8.33, -12.803, -7.004, -11.571, -4.494],
            [-10.34, -1.979, -8.54, -1.313, -7.258, 0.058],
            [-7.048, 0.283, -6.878, 0.545, -6.746, 0.834],
            [-6.708, 0.916, -6.67, 1.0, -6.634, 1.09],
            [-6.634, 1.09, -3.552, -1.499, -0.0, 0.0]
          ],
          path: null
        },
        back: {
          x: 33.5278,
          y: 123.593,
          points: [
            [0.0, 0.0, 15.539, -8.187, 35.268, 0.0],
            [35.039, 1.761, 34.829, 3.109, 34.554, 4.957],
            [34.056, 8.3, 33.771, 8.086, 33.701, 12.137],
            [33.615, 16.972, 36.26, 23.229, 36.26, 23.229],
            [38.742, 32.557, 46.047, 34.604, 46.047, 34.604],
            [46.109, 35.954, 46.525, 37.117, 46.358, 39.569],
            [46.358, 39.569, 37.584, 36.287, 32.363, 41.508],
            [27.143, 46.729, 32.866, 56.151, 32.866, 56.151],
            [30.582, 57.167, 28.227, 58.231, 26.556, 59.023],
            [24.752, 59.879, 24.108, 60.245, 23.879, 60.792],
            [23.882, 60.793],
            [23.882, 60.793, 22.446, 56.57, 17.567, 56.57],
            [12.688, 56.57, 11.464, 61.048, 11.465, 61.048],
            [11.322, 60.342, 10.78, 60.005, 8.712, 59.023],
            [7.041, 58.231, 4.686, 57.167, 2.4, 56.151],
            [2.4, 56.151, 8.275, 46.729, 3.054, 41.508],
            [-2.167, 36.287, -11.089, 39.586, -11.089, 39.586],
            [-11.09, 39.586],
            [-11.261, 37.122, -10.843, 35.957, -10.779, 34.604],
            [-10.779, 34.604, -3.476, 32.557, -0.993, 23.229],
            [-0.993, 23.229, 1.65, 16.972, 1.566, 12.137],
            [1.496, 8.086, 1.211, 8.3, 0.713, 4.957],
            [0.437, 3.109, 0.229, 1.761, 0.0, 0.0]
          ],
          path: null
        },
        hips: {
          x: 84.6671,
          y: 170.157,
          points: [
            [0.631, 5.709, 0.729, 10.547, 0.439, 13.599],
            [-0.032, 18.623, -1.36, 23.173, -2.451, 29.359],
            [-2.705, 31.806, -2.902, 33.499, -3.087, 34.923],
            [-22.815, 26.736, -38.354, 34.923, -38.355, 34.923],
            [-38.54, 33.499, -38.737, 31.806, -38.991, 29.359],
            [-40.082, 23.173, -41.408, 18.623, -41.883, 13.599],
            [-42.17, 10.547, -42.073, 5.709, -41.442, 0.0],
            [-41.442, 0.0, -33.424, -3.098, -21.917, -1.765],
            [-21.621, 0.9, -21.251, 2.616, -20.768, 2.616],
            [-20.352, 2.616, -19.979, 0.713, -19.656, -2.098],
            [-19.656, -2.098, -7.73, -4.301, -0.0, 0.0]
          ],
          path: null
        },
        thigh_right: {
          x: 77.9392,
          y: 203.873,
          points: [
            [0.577, 3.163, 1.649, 6.54, 3.117, 13.386],
            [4.009, 17.552, 4.639, 21.602, 5.046, 25.287],
            [-2.685, 20.986, -14.61, 23.189, -14.61, 23.189],
            [-13.726, 15.485, -13.211, 0.963, -13.188, 0.117],
            [-13.174, -0.202, -13.162, -0.519, -13.149, -0.831],
            [-13.149, -0.831, -6.381, -2.224, 0.0, 0.0]
          ],
          path: null
        },
        thigh_left: {
          x: 35.9236,
          y: 202.765,
          points: [
            [-0.604, 2.944, -1.615, 6.304, -2.955, 12.555],
            [-3.848, 16.721, -4.478, 20.771, -4.885, 24.456],
            [-4.885, 24.456, 3.134, 21.358, 14.641, 22.691],
            [13.982, 16.771, 13.691, 6.162, 13.35, -0.714],
            [13.337, -0.953, 13.325, -1.19, 13.314, -1.425],
            [6.193, -2.41, -0.002, -0.001, -0.0, -0.0]
          ],
          path: null
        },
        knee_right: {
          x: 79.3116,
          y: 230.431,
          points: [
            [-0.237, 5.816, -1.323, 10.914, -1.514, 14.349],
            [-1.514, 16.502, -1.347, 18.178, -1.029, 19.918],
            //[-1.028000, 19.918000],
            [-7.41, 17.694, -14.179, 19.087, -14.179, 19.087],
            [-13.984, 14.194, -13.826, 10.277, -13.291, 8.875],
            [-12.854, 7.725, -13.174, 4.638, -13.572, 0.0],
            [-13.572, 0.0, -7.405, -1.557, 0.0, 0.0]
          ],
          path: null
        },
        knee_left: {
          x: 34.8676,
          y: 228.519,
          points: [
            [0.336, 5.218, 1.264, 9.766, 1.438, 12.915],
            [1.438, 15.407, 1.214, 17.261, 0.792, 19.315],
            //[0.790000, 19.314000],
            [0.79, 19.314, 6.985, 16.905, 14.106, 17.891],
            [13.865, 12.89, 13.76, 8.866, 13.216, 7.441],
            [12.794, 6.332, 13.078, 3.42, 13.455, -0.947],
            [5.469, -2.447, 0.001, 0.0, 0.0, 0.0]
          ],
          path: null
        },
        calf_right: {
          x: 63.313,
          y: 261.894,
          points: [
            [-0.09, 1.355, -0.228, 2.666, -0.416, 3.728],
            [-1.032, 7.185, -2.43, 11.854, -1.933, 19.035],
            [-1.818, 20.688, -1.692, 22.212, -1.573, 23.598],
            [-1.573, 23.598, 4.594, 22.041, 11.997, 23.598],
            [11.999, 23.598],
            [12.115, 20.743, 12.026, 17.716, 11.527, 14.628],
            [10.398, 7.639, 9.217, 4.331, 8.296, 0.631],
            [4.521, -0.829, 0.0, 0.0, 0.0, 0.0]
          ],
          path: null
        },
        calf_left: {
          x: 34.8676,
          y: 228.519,
          points: [
            [-0.21, -3.265, -0.188, -6.792, 0.396, -10.403],
            [1.461, -16.993, 2.572, -20.311, 3.468, -23.77],
            //[3.467000, -23.770000],
            [3.467, -23.77, 6.735, -25.539, 11.934, -24.887],
            [11.933, -24.887],
            [12.022, -23.583, 12.157, -22.328, 12.34, -21.304],
            [12.956, -17.847, 14.354, -13.178, 13.856, -5.996],
            [13.729, -4.147, 13.587, -2.46, 13.455, -0.947],
            [5.469, -2.447, 0.001, 0.0, 0.001, 0.0]
          ],
          path: null
        },
        foot_right: {
          x: 63.313,
          y: 261.894,
          points: [
            [0.203, -3.065, 0.162, -6.359, -0.085, -7.518],
            [-0.439, -9.188, -0.688, -9.792, -0.439, -10.645],
            [-0.259, -11.265, -0.119, -11.5, -0.218, -12.462],
            [-0.233, -12.184, -0.254, -11.903, -0.328, -11.637],
            [-0.333, -11.621, -0.382, -11.573, -0.375, -11.61],
            [-0.221, -12.297, -0.31, -12.961, -0.414, -13.646],
            [-0.415, -13.653, -0.403, -13.665, -0.397, -13.676],
            [-0.413, -13.76, -0.423, -13.826, -0.439, -13.917],
            [-0.865, -16.228, -0.724, -17.614, -0.227, -18.322],
            [0.021, -18.676, 0.285, -18.925, 0.497, -19.25],
            [0.495, -19.297, 0.494, -19.351, 0.492, -19.394],
            [0.49, -19.416, 0.554, -19.494, 0.562, -19.462],
            [0.568, -19.439, 0.567, -19.406, 0.572, -19.382],
            [0.748, -19.69, 0.879, -20.074, 0.91, -20.67],
            [0.982, -22.02, 1.194, -22.623, 1.87, -23.405],
            [2.546, -24.188, 2.9, -24.687, 2.973, -25.434],
            [3.043, -26.18, 3.647, -27.104, 4.963, -27.21],
            [6.278, -27.317, 6.741, -26.287, 6.421, -25.646],
            [6.101, -25.007, 5.604, -24.119, 6.207, -23.941],
            [6.314, -23.909, 6.402, -23.927, 6.484, -23.964],
            [6.557, -24.058, 6.63, -24.15, 6.688, -24.263],
            [6.708, -24.297, 6.797, -24.346, 6.765, -24.272],
            [6.73, -24.197, 6.675, -24.138, 6.632, -24.067],
            [6.922, -24.364, 7.123, -25.081, 7.629, -25.789],
            [8.341, -26.784, 9.265, -26.357, 9.514, -25.646],
            [10.615, -26.003, 11.646, -25.469, 11.397, -24.649],
            [12.321, -25.114, 12.677, -23.977, 12.392, -23.191],
            [13.28, -22.837, 12.961, -21.877, 12.037, -21.166],
            [11.529, -20.775, 11.433, -20.775, 10.58, -19.355],
            [9.265, -17.472, 6.88, -14.167, 6.88, -12.515],
            [7.506, -10.736, 7.355, -10.36, 7.205, -9.634],
            [7.055, -8.907, 6.694, -8.311, 7.452, -3.479],
            [7.694, -1.953, 7.979, -0.638, 8.296, 0.631],
            [4.521, -0.829, 0.0, 0.0, 0.0, 0.0]
          ],
          path: null
        },
        foot_left: {
          x: 50.7783,
          y: 261.702,
          points: [
            [-0.215, -3.105, -0.176, -6.486, 0.074, -7.662],
            [0.431, -9.333, 0.679, -9.937, 0.431, -10.789],
            [0.182, -11.643, 0.004, -11.749, 0.431, -14.062],
            [0.856, -16.372, 0.715, -17.759, 0.218, -18.467],
            [-0.281, -19.179, -0.85, -19.464, -0.92, -20.814],
            [-0.991, -22.164, -1.205, -22.768, -1.881, -23.55],
            [-2.555, -24.333, -2.911, -24.831, -2.981, -25.578],
            [-3.053, -26.324, -3.656, -27.248, -4.973, -27.354],
            [-6.287, -27.462, -6.75, -26.432, -6.43, -25.791],
            [-6.109, -25.151, -5.612, -24.264, -6.216, -24.086],
            [-6.819, -23.906, -6.927, -24.937, -7.639, -25.934],
            [-8.35, -26.929, -9.273, -26.502, -9.523, -25.791],
            [-10.625, -26.147, -11.655, -25.613, -11.406, -24.794],
            [-12.331, -25.259, -12.687, -24.121, -12.402, -23.336],
            [-13.29, -22.981, -12.971, -22.021, -12.047, -21.311],
            [-11.538, -20.92, -11.441, -20.92, -10.589, -19.5],
            [-9.273, -17.616, -6.888, -14.312, -6.888, -12.659],
            [-7.515, -10.881, -7.365, -10.505, -7.214, -9.778],
            [-7.063, -9.052, -6.702, -8.455, -7.461, -3.623],
            [-7.743, -1.844, -8.085, -0.351, -8.465, 1.117],
            //[-8.466000, 1.117000],
            [-8.466, 1.117, -5.197, -0.652, 0.001, 0.0]
          ],
          path: null
        }
      }
    };
  },
  computed: {
    scale: function() {
      let scaleX = this.canvasWidth / this.config.bodyWidth;
      let scaleY = this.canvasHeight / this.config.bodyHeight;
      return Math.min(scaleX, scaleY);
    },
    offset: function() {
      return {
        x: (this.canvasWidth - this.config.bodyWidth * this.scale) / 2,
        y: (this.canvasHeight - this.config.bodyHeight * this.scale) / 2
      };
    },
    shapePaths: function() {
      let paths = {
        head: null,
        neck: null,
        shoulder_right: null,
        shoulder_left: null,
        upperarm_right: null,
        upperarm_left: null,
        lowerarm_right: null,
        lowerarm_left: null,
        hand_right: null,
        hand_left: null,
        back: null,
        hips: null,
        thigh_right: null,
        thigh_left: null,
        knee_right: null,
        knee_left: null,
        calf_right: null,
        calf_left: null,
        foot_right: null,
        foot_left: null
      };
      for (let bpni = 0; bpni < this.bodyPartNames.length; bpni++) {
        let bodyPartName = this.bodyPartNames[bpni];
        let bodyPartShape = this.bodyParts[bodyPartName];
        let shapePath = new Path2D();
        shapePath.moveTo(
          this.offset.x + bodyPartShape.x * this.scale,
          this.offset.y + bodyPartShape.y * this.scale
        );
        for (let pi = 0; pi < bodyPartShape.points.length; pi++) {
          let point = bodyPartShape.points[pi];
          if (point.length === 6) {
            shapePath.bezierCurveTo(
              this.offset.x +
                (bodyPartShape.x + point[0] * 1.3333) * this.scale,
              this.offset.y +
                (bodyPartShape.y - point[1] * 1.3333) * this.scale,
              this.offset.x +
                (bodyPartShape.x + point[2] * 1.3333) * this.scale,
              this.offset.y +
                (bodyPartShape.y - point[3] * 1.3333) * this.scale,
              this.offset.x +
                (bodyPartShape.x + point[4] * 1.3333) * this.scale,
              this.offset.y + (bodyPartShape.y - point[5] * 1.3333) * this.scale
            );
          } else if (point.length === 2) {
            shapePath.lineTo(
              this.offset.x +
                (bodyPartShape.x + point[0] * 1.3333) * this.scale,
              this.offset.y + (bodyPartShape.y - point[1] * 1.3333) * this.scale
            );
          }
        }
        shapePath.closePath();
        paths[bodyPartName] = shapePath;
      }
      return paths;
    }
  },
  watch: {
    bodyPartsSelected: {
      handler() {
        // console.log(value, "bodyPicker");
        // this.$emit('input', value);
      },
      deep: true
    }
  },
  methods: {
    partNameFromPoint() {
      for (let bpni = 0; bpni < this.bodyPartNames.length; bpni++) {
        let bodyPartName = this.bodyPartNames[bpni];
        let bodyPartShape = this.shapePaths[bodyPartName];
        if (
          this.context.isPointInPath(bodyPartShape, this.mouse.x, this.mouse.y)
        ) {
          return bodyPartName;
        }
      }
      return null;
    },
    render() {
      this.renderRequest = requestAnimationFrame(this.render);
      this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      for (let bpni = 0; bpni < this.bodyPartNames.length; bpni++) {
        let bodyPartName = this.bodyPartNames[bpni];
        let bodyPartShape = this.shapePaths[bodyPartName];
        let hover = this.context.isPointInPath(
          bodyPartShape,
          this.mouse.x,
          this.mouse.y
        );
        this.context.save();
        this.context.beginPath();
        this.context.fillStyle = this.bodyPartsSelected[bodyPartName]
          ? hover
            ? this.config.selectedHoverColour
            : this.config.selectedColour
          : hover
          ? this.config.unselectedHoverColour
          : this.config.unselectedColour;
        this.context.strokeStyle = this.config.lineColour;
        this.context.lineWidth = 0.521 * this.scale;
        this.context.lineCap = "round";
        this.context.lineJoin = "round";
        this.context.stroke(bodyPartShape);
        this.context.fill(bodyPartShape);
        this.context.restore();
      }
    },
    onResize() {
      this.canvasWidth = this.$refs["widget"].width = this.$refs[
        "widget"
      ].parentElement.clientWidth;
      this.canvasHeight = this.$refs["widget"].height = this.$refs[
        "widget"
      ].parentElement.clientHeight;
    },
    onMouseMove(event) {
      this.mouse.x = event.offsetX;
      this.mouse.y = event.offsetY;
    },
    onMouseDown(event) {
      let partName = this.partNameFromPoint(event.offsetX, event.offsetY);
      if (partName !== null) {
        this.$set(
          this.bodyPartsSelected,
          partName,
          !this.bodyPartsSelected[partName]
        );
      }
    },
    onSubmitBtn() {
      this.$store.dispatch("currentSection", this.section.id);
      this.$store.dispatch("currentQuestion", this.questionId);
      this.$store.dispatch("nextQuestion", this.options.nextQuestion);
      this.$store.dispatch("nextSection", this.options.nextSection);

      const answer =
        `JSON_OBJECT(` +
        `'head',${this.bodyPartsSelected.head},` +
        `'neck',${this.bodyPartsSelected.neck},` +
        `'shoulder_right',${this.bodyPartsSelected.shoulder_right},` +
        `'shoulder_left',${this.bodyPartsSelected.shoulder_left},` +
        `'upperarm_right',${this.bodyPartsSelected.upperarm_right},` +
        `'upperarm_left',${this.bodyPartsSelected.upperarm_left},` +
        `'lowerarm_right',${this.bodyPartsSelected.lowerarm_right},` +
        `'lowerarm_left',${this.bodyPartsSelected.lowerarm_left},` +
        `'hand_right',${this.bodyPartsSelected.hand_right},` +
        `'hand_left',${this.bodyPartsSelected.hand_left},` +
        `'back',${this.bodyPartsSelected.back},` +
        `'hips',${this.bodyPartsSelected.hips},` +
        `'thigh_right',${this.bodyPartsSelected.thigh_right},` +
        `'thigh_left',${this.bodyPartsSelected.thigh_left},` +
        `'knee_right',${this.bodyPartsSelected.knee_right},` +
        `'knee_left',${this.bodyPartsSelected.knee_left},` +
        `'calf_right',${this.bodyPartsSelected.calf_right},` +
        `'calf_left',${this.bodyPartsSelected.calf_left},` +
        `'foot_right',${this.bodyPartsSelected.foot_right},` +
        `'foot_left',${this.bodyPartsSelected.foot_left}` +
        `)`;
      this.$store.dispatch("addAnswer", {
        questionId: this.questionId,
        sectionId: this.section.id,
        answer: answer
      });
      this.$emit("saveAnswer");
    },
    init() {
      this.context = this.$refs["widget"].getContext("2d");
      this.$refs["widget"].addEventListener("mousemove", this.onMouseMove);
      this.$refs["widget"].addEventListener("mousedown", this.onMouseDown);
      window.addEventListener("resize", this.onResize, false);
      this.onResize();
      requestAnimationFrame(this.render); // Animation frame's are better than timeouts - they let the browser request a refresh - good on mobiles if the tab is not active.
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    cancelAnimationFrame(this.render);
    window.removeEventListener("resize", this.onResize);
    this.$refs["widget"].removeEventListener("mousemove", this.onMouseMove);
    this.$refs["widget"].removeEventListener("mousedown", this.onMouseDown);
  }
};
</script>
