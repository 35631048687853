export default class Answer {
  constructor({
    questionId,
    sectionId,
    answer,
    nextQuestionId,
    nextSectionId,
    answersObj,
    mysqlJsonStructure
  }) {
    this.answersObj = answersObj;
    this.mysqlJsonStructure = mysqlJsonStructure;

    // Check if answer has already been added in answer object if so remove and return true
    const hasAnswer = this._hasAnswer(questionId, sectionId, answersObj);

    // If answer exists remove from object and also remove intermediate answers
    if (hasAnswer) {
      this.answersObj = this._removeFromAnswerObj(
        questionId,
        sectionId,
        answersObj
      );
      // this.answersObj = this._removeIntermediateAnswers(questionId, sectionId, answersObj, nextQuestionId, nextSectionId);
    }

    // Add to answer object
    this.answersObj.push(
      this._addToAnswerObject(questionId, sectionId, answer)
    );

    // If answer exists rebuild the whole json mysql structure (SLOW) else append (FAST)
    if (!hasAnswer) {
      this.mysqlJsonStructure = this._appendToMysqlQuery(
        questionId,
        sectionId,
        answer,
        mysqlJsonStructure
      );
      return;
    }
    this.mysqlJsonStructure = this._rebuildMysqlQuery(answersObj);
  }

  _hasAnswer(questionId, sectionId, answersObj) {
    if (answersObj.length > 0) {
      var result = answersObj.filter(function(answer) {
        return (
          answer.questionId === questionId && answer.sectionId === sectionId
        );
      });
      if (result.length > 0) {
        return true;
      }
    }
    return false;
  }

  _removeFromAnswerObj(questionId, sectionId, answersObj) {
    if (answersObj.length > 0) {
      const index = answersObj.findIndex(
        answer =>
          answer.questionId === questionId && answer.sectionId === sectionId
      );
      answersObj.splice(index);
      return answersObj;
    }
  }

  _addToAnswerObject(questionId, sectionId, answer) {
    return {
      questionId: questionId,
      sectionId: sectionId,
      answer: answer
    };
  }

  _rebuildMysqlQuery(answersObj) {
    let mysqlJson = "";
    let mysqlJsonStructure = "";
    answersObj.forEach(function(answer) {
      if (!answer.answer.includes("JSON_OBJECT")) {
        mysqlJson = `'${answer.sectionId}:${answer.questionId}','${answer.answer}',`;
      } else {
        mysqlJson = `'${answer.sectionId}:${answer.questionId}',${answer.answer},`;
      }
      if (mysqlJson) {
        mysqlJsonStructure = mysqlJsonStructure.concat(mysqlJson);
      } else {
        mysqlJsonStructure = mysqlJson;
      }
    });
    return mysqlJsonStructure;
  }

  _appendToMysqlQuery(questionId, sectionId, answer, mysqlJsonStructure) {
    let mysqlJson = "";
    if (!answer.includes("JSON_OBJECT")) {
      mysqlJson = `'${sectionId}:${questionId}','${answer}',`;
    } else {
      mysqlJson = `'${sectionId}:${questionId}',${answer},`;
    }

    if (mysqlJsonStructure) {
      mysqlJsonStructure = mysqlJsonStructure.concat(mysqlJson);
    } else {
      mysqlJsonStructure = mysqlJson;
    }
    return mysqlJsonStructure;
  }

  _removeIntermediateAnswers(
    questionId,
    sectionId,
    answersObj,
    nextQuestionId,
    nextSectionId
  ) {
    if (sectionId === nextSectionId) {
      if (nextQuestionId - questionId > 1) {
        // Skipped questions
        for (var i = questionId + 1; i < nextQuestionId; i++) {
          const index = answersObj.findIndex(
            answer => answer.questionId === i && answer.sectionId === sectionId
          );
          answersObj.splice(index, 1);
        }
      }
    }
    return answersObj;
  }
}

export function GetMysqlDataStructure({ mysqlJsonStructure }) {
  return mysqlJsonStructure;
}

export function GetAnswerObject({ answersObj }) {
  return answersObj;
}
