<template>
  <div>
    <img
      v-if="options.imageUrl"
      :src="options.imageUrl"
      :tag="options.imageText"
    />
    <img
      v-else-if="section.imageUrl"
      :src="section.imageUrl"
      :tag="section.imageText"
    />
    <img
      v-if="question.imageUrl"
      :src="question.imageUrl"
      :tag="question.imageText"
    />
    <h3>{{ question.question }}</h3>
    <h4 v-if="question.subQuestion" class="subtitle mb-2">
      {{ question.subQuestion }}
    </h4>
    <div class="buttons-container-height">
      <button
        v-for="option in options"
        :key="option.text"
        class="btn btn-default btn-lg answers-button active"
        @click="submitAnswer(option)"
      >
        {{ option.text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    questionId: Number,
    sectionId: Number,
    section: Object,
    question: Object
  },
  methods: {
    submitAnswer(option) {
      this.$store.dispatch("currentSection", this.sectionId);
      this.$store.dispatch("currentQuestion", this.question.id);
      this.$store.dispatch("nextSection", option.nextSection);
      this.$store.dispatch("nextQuestion", option.nextQuestion);
      this.$store.dispatch("addAnswer", {
        questionId: this.questionId,
        sectionId: this.sectionId,
        answer: option.value
      });
      this.$emit("saveAnswer");
    }
  }
};
</script>
