<template>
  <div>
    <img
      v-if="question.imageUrl"
      :src="question.imageUrl"
      :tag="question.imageText"
    />
    <img
      v-else-if="section.imageUrl"
      :src="section.imageUrl"
      :tag="section.imageText"
    />
    <h3>{{ question.headline }}</h3>
    <p class="my-4">
      {{ question.body }}
    </p>
    <button
      v-if="question.buttonText"
      class="btn btn-default btn-lg answers-button my-2"
      @click="onSubmitBtn()"
    >
      {{ question.buttonText }}
    </button>
    <button
      v-else
      class="btn btn-default btn-lg answers-button my-2"
      @click="onSubmitBtn()"
    >
      Next
    </button>
  </div>
</template>

<script>
export default {
  props: {
    question: Object,
    questionId: Number,
    sectionId: Number,
    section: Object
  },
  data() {
    return {};
  },
  methods: {
    onSubmitBtn() {
      this.$store.dispatch("nextQuestion", this.question.nextQuestion);
      this.$store.dispatch("nextSection", this.question.nextSection);
      this.$store.dispatch("addAnswerPath", {
        questionId: this.questionId,
        sectionId: this.sectionId,
        answer: null
      });
      this.$emit("saveAnswer");
    }
  }
};
</script>
