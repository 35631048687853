var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.message)?_c('div',{class:_vm.message.type},[_vm._v(" "+_vm._s(_vm.message.message)+" ")]):_vm._e(),_c('BackNavigationButton',{on:{"navigateBack":_vm.onNavigateBack}}),_c('full-page',{ref:"fullpage",attrs:{"options":_vm.options,"skip-init":true}},_vm._l((_vm.$store.state.survey.sections),function(section){return _c('div',{key:section.id,staticClass:"section"},_vm._l((section.items),function(question){return _c('div',{key:question.id,staticClass:"slide",attrs:{"data-anchor":("slide" + (question.id))}},[_c('div',{staticClass:"container"},[(
              question.type === 'question' && question.subType === 'bodyimage'
            )?_c('BodyImageSelector',{attrs:{"options":question,"questionId":question.id,"section":section,"question":question.question},on:{"saveAnswer":_vm.onSetAnswer}}):_vm._e(),(
              question.type === 'page' &&
                question.subType === 'text' &&
                question.nextQuestion === -1
            )?_c('FinalPageQuestionText',{attrs:{"question":question,"questionId":question.id,"sectionId":section.id,"section":section},on:{"saveAnswer":_vm.onSetAnswer}}):_vm._e(),(
              question.type === 'page' &&
                question.subType === 'text' &&
                question.nextQuestion !== -1
            )?_c('QuestionText',{attrs:{"question":question,"questionId":question.id,"sectionId":section.id,"section":section},on:{"saveAnswer":_vm.onSetAnswer}}):_vm._e(),(
              question.type === 'question' && question.subType === 'buttons'
            )?_c('QuestionRadio',{attrs:{"options":question.choices,"question":question,"questionId":question.id,"sectionId":section.id,"section":section},on:{"saveAnswer":_vm.onSetAnswer}}):_vm._e()],1)])}),0)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }