import { API } from "aws-amplify";

export function getAssessmentById(apiKey, assessmentId) {
  const getAssessment = /* GraphQL */ `
    query GetAssessment($app_key: String!, $assessment_id: String!) {
      getAssessment(app_key: $app_key, assessment_id: $assessment_id) {
        app_key
        referrer
        answers
        assessment_id
        survey_id
        completed_at
        created_at
        expires_at
        updated_at
      }
    }
  `;
  var assessmentOutput = new Promise(async function (resolve, reject) {
    const {
      data: { getAssessment: assessment },
    } = await API.graphql({
      query: getAssessment,
      variables: {
        app_key: apiKey,
        assessment_id: assessmentId,
      }, // 9C4E246671D111EB8ADFE36FDDF37C8E
      authMode: "API_KEY",
    });
    return resolve(assessment);
  });
  return assessmentOutput;
}

export function addAssessmentAnswer(assessmentId, appKey, answers) {
  const updateAssessment = /* GraphQL */ `
    mutation UpdateAssessment(
      $updateAssessmentInput: UpdateAssessmentInput!
    ) {
      updateAssessment(updateAssessmentInput: $updateAssessmentInput) {
        assessment_id
        app_key
        answers
      }
    }
  `;
  var assessmentOutput = new Promise(async function (resolve, reject) {
    const updateAss = await API.graphql({
      query: updateAssessment,
      variables: {
        updateAssessmentInput: {
          assessment_id: assessmentId,
          app_key: appKey,
          answers: answers,
        },
      },
      authMode: "API_KEY",
    });
    return resolve(updateAss);
  });
  return assessmentOutput;
}

export function updateAssessmentCompletedDated(assessmentId, appKey, completedAt) {
  const updateAssessment = /* GraphQL */ `
    mutation UpdateAssessment(
      $updateAssessmentInput: UpdateAssessmentInput!
    ) {
      updateAssessment(updateAssessmentInput: $updateAssessmentInput) {
        assessment_id
        app_key
        answers
      }
    }
  `;
  var assessmentOutput = new Promise(async function (resolve, reject) {
    const updateAssCompleteDate = await API.graphql({
      query: updateAssessment,
      variables: {
        updateAssessmentInput: {
          assessment_id: assessmentId,
          app_key: appKey,
          completed_at: completedAt,
        },
      },
      authMode: "API_KEY",
    });
    return resolve(updateAssCompleteDate);
  });
  return assessmentOutput;
}


